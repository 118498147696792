// Messages
$success: #25bb48;
$error: #e4002b;
$error-light: #fc5a60;

// App palette
$primary: rgba(186, 60, 51); // #ba3c33;
$secondary: rgba(250, 162, 91); // #faa25b

// Hover
$primary-hover: rgba(186, 60, 51, 0.9);
$secondary-hover: rgba(250, 162, 91, 0.9);
$third-hover: #f9f9f9;

$primary-cover: rgba(186, 60, 51, 0.1);

$blue-for-upload: #0044ff;

$accent: #f6b04e;
$green: #7ed321;
$green-cover: rgb(126, 211, 33, 0.1); // #7ed321

$placeholder: #8f8f8f;
$light-grey: #f2f2f2;
$soft-gray: #f9f9f9;

// Grayscale
$black: #333333;
$gray1: #727272;
$gray2: #ababab;
$gray3: #dbdbdb;
$gray4: #efefef;
$gray5: #d3d3d3;
$white: #ffffff;

// Media Queries
$media-xxs: 480px;
$media-xs: 600px;
$media-sm: 960px;
$media-md: 1280px;
$media-lg: 1920px;
