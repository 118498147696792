// Shadows
@mixin small-shadow {
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.04),
        0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 2px 0px rgba(0, 0, 0, 0.04),
        0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 0px 2px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.04);
}
@mixin normal-shadow {
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
        0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
        0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

@mixin medium-shadow {
    -webkit-box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
        0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 16px 24px 0px rgba(0, 0, 0, 0.04), 0px 2px 6px 0px rgba(0, 0, 0, 0.04),
        0px 0px 1px 0px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 16px 24px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
}

@mixin big-shadow {
    -webkit-box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.04),
        0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    -moz-box-shadow: 0px 24px 32px 0px rgba(0, 0, 0, 0.04), 0px 16px 24px 0px rgba(0, 0, 0, 0.04),
        0px 4px 8px 0px rgba(0, 0, 0, 0.04), 0px 0px 1px rgba(0, 0, 0, 0.04);
    box-shadow: 0px 24px 32px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04), 0px 4px 8px rgba(0, 0, 0, 0.04),
        0px 0px 1px rgba(0, 0, 0, 0.04);
}

//Border radius
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    -o-border-radius: $radius;
    border-radius: $radius;
}

@mixin border-top-left-radius($radius) {
    -webkit-border-top-left-radius: $radius;
    -moz-border-top-left-radius: $radius;
    -ms-border-top-left-radius: $radius;
    -o-border-top-left-radius: $radius;
    border-top-left-radius: $radius;
}

@mixin border-top-right-radius($radius) {
    -webkit-border-top-right-radius: $radius;
    -moz-border-top-right-radius: $radius;
    -ms-border-top-right-radius: $radius;
    -o-border-top-right-radius: $radius;
    border-top-right-radius: $radius;
}

@mixin border-bottom-right-radius($radius) {
    -webkit-border-bottom-right-radius: $radius;
    -moz-border-bottom-right-radius: $radius;
    -ms-border-bottom-right-radius: $radius;
    -o-border-bottom-right-radius: $radius;
    border-bottom-right-radius: $radius;
}

@mixin border-bottom-left-radius($radius) {
    -webkit-border-bottom-left-radius: $radius;
    -moz-border-bottom-left-radius: $radius;
    -ms-border-bottom-left-radius: $radius;
    -o-border-bottom-left-radius: $radius;
    border-bottom-left-radius: $radius;
}

// Pulsanti
@mixin main-button-bg($bg) {
    background: $bg !important;
    &:hover {
        background: lighten($bg, 4%) !important;
        transition: all 0.3s ease;
    }
    &:active {
        background: lighten($bg, 8%) !important;
    }
}

@mixin layout-row-gap($gapValue) {
    // div, > p, > label, > svg-icon, > welion-select, > welion-button, > welion-input, > welion-upload, > welion-datepicker, > welion-check, > welion-switch, > welion-radio, > welion-text-area
    & > * {
        margin-right: $gapValue;
        &:last-child {
            margin-right: inherit ;
        }
    }
}

@mixin layout-column-gap($gapValue) {
    // div, > p, > label, > svg-icon, > welion-select, > welion-button, > welion-input, > welion-upload, > welion-datepicker, > welion-check, > welion-switch, > welion-radio, > welion-text-area
    & > * {
        margin-bottom: $gapValue;
        &:last-child {
            margin-bottom: inherit;
        }
    }
}

@mixin fixed-size($width, $height) {
    height: $height;
    width: $width;
    max-height: $height;
    max-width: $width;
    min-height: $height;
    min-width: $width;
}

@mixin fixed-height($value) {
    height: $value;
    max-height: $value;
    min-height: $value;
}

@mixin fixed-width($value) {
    width: $value;
    max-width: $value;
    min-width: $value;
}

//Generatore di classi margin padding
@mixin margin-padding($attr, $attr-count: 10, $attr-steps: 10, $unit: "%") {
    $attr-list: null;
    @for $i from 0 through $attr-count {
        $attr-value: $attr-steps * $i;
        .#{$attr}#{$attr-value} {
            #{$attr}: #{$attr-value}#{$unit};
        }
        $attr-list: append($attr-list, unquote(".#{$attr}-#{$attr-value}"), comma);
    }
}

// Bordo tratteggiato - compatibile con IE
@mixin border-spaced($dashWidth, $color, $radius, $borderWidth) {
  position: relative;
  z-index: 0;
  &::before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-image: linear-gradient(white, white), linear-gradient(to bottom, $color 50%, rgba(255, 255, 255, 0) 0%);
    background-size: 100% ($dashWidth * 2);
    background-repeat: repeat-y;
    border: $borderWidth solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    z-index: -1;
    border-top: none;
    border-bottom: none;
    border-radius: $radius;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2px;
    right: 2px;
    background-image: linear-gradient(white, white), linear-gradient(to right, $color 50%, rgba(255, 255, 255, 0) 0%);
    background-size: ($dashWidth * 2) 100%;
    background-repeat: repeat-x;
    border: $borderWidth solid transparent;
    background-origin: border-box;
    background-clip: content-box, border-box;
    z-index: -1;
    border-right: none;
    border-left: none;
    border-radius: $radius;
  }
}

@mixin set-size($size) {
  width: $size;
  height: $size;
  min-width: $size;
  min-height: $size;
  max-width: $size;
  max-height: $size;
}
