@use "../../../assets/sass/helpers/parameters.scss" as *;
@use "../../../assets/sass/helpers/responsive" as *;
@use "../../../assets/sass/helpers/_mixins.scss" as *;

@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");

*:focus {
  outline: none;
}

*:focus-visible {
  outline: 3px solid #00746d;
  outline-offset: 3px;
  //background-color: lighten($sfondo-header, 10%); // Sfondo leggermente più chiaro
}

* {
  font-family: "Metropolis", sans-serif;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  background-color: #fff;
}

html,
body {
  width: 100%;
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  height: 100%;
  min-height: 100%;
}

body.modal-open {
  overflow: hidden !important;
}

.height-application {
  height: 100% !important;
  min-height: 100% !important;
}

// #region Font Metropolis

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 100;
  src:
    local(""),
    url("../fonts/Metropolis-Thin.woff2") format("woff2") /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../fonts/Metropolis-Thin.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 200;
  src:
    local(""),
    url("../fonts/Metropolis-Light.woff2") format("woff2") /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../fonts/Metropolis-Light.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 400;
  src:
    local(""),
    url("../fonts/Metropolis-Regular.woff2") format("woff2") /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../fonts/Metropolis-Regular.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 500;
  src:
    local(""),
    url("../fonts/Metropolis-Medium.woff2") format("woff2") /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../fonts/Metropolis-Medium.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 600;
  src:
    local(""),
    url("../fonts/Metropolis-SemiBold.woff2") format("woff2") /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../fonts/Metropolis-SemiBold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 700;
  src:
    local(""),
    url("../fonts/Metropolis-Bold.woff2") format("woff2") /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../fonts/Metropolis-Bold.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
  font-family: "Metropolis";
  font-style: normal;
  font-weight: 900;
  src:
    local(""),
    url("../fonts/Metropolis-Black.woff2") format("woff2") /* Chrome 26+, Opera 23+, Firefox 39+ */,
    url("../fonts/Metropolis-Black.woff") format("woff");
  /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

// #endregion

* {
  font-family: "Metropolis", Arial, sans-serif;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  // No highlight on tap
  -webkit-tap-highlight-color: transparent;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

*:before,
*:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

img {
  vertical-align: middle;
  border: 0;
}

a {
  text-decoration: none;
}

svg:not(:root) {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
p,
a {
  margin: 0;
}

a {
  cursor: pointer;
}

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
  background: none;
  border: none;
}

button[disabled],
html input[disabled] {
  cursor: default !important;
  pointer-events: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

[role="button"] {
  cursor: pointer;
}

// Typography
.title-page {
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  letter-spacing: 0.2px;
}

.title-section {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  letter-spacing: 0.2px;
}

.text-medium {
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.text-big {
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: 0.2px;
}

.text-default {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
}

.text-small {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
}

.text-label-form {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
}

.title-paragraph {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

.text-medium-link {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

.text-default-link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

.text-small-link {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.2px;
  text-decoration: underline;
}

// svg Colors
.green-success {
  stroke: $success;
  color: $success;
}

.orange-accent {
  svg {
    stroke: $accent;
    color: $accent;
  }
}

.gray-gray1 {
  svg {
    stroke: $gray1;
    color: $gray1;
  }
}

.gray-gray2 {
  svg {
    stroke: $gray2;
    color: $gray2;
  }
}

.blue-primary {
  cursor: pointer;

  svg {
    color: $primary;
  }
}

.white-svg {
  cursor: pointer;

  svg {
    color: $white;
  }
}

.smaller-size {
  width: 14px;
  height: 14px;
  min-width: 14px;
  min-height: 14px;
  max-width: 14px;
  max-height: 14px;
}

.small-size {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  max-width: 16px;
  max-height: 16px;
}

.medium-size {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.regular-size {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  max-width: 24px;
  max-height: 24px;
}

.regular-size-avatar-img {
  width: 48px;
  height: 48px;
  min-width: 48px;
  min-height: 48px;
  max-width: 48px;
  max-height: 48px;
}

.big-size {
  width: 62px;
  height: 62px;
  min-width: 62px;
  min-height: 62px;
  max-width: 62px;
  max-height: 62px;
}

.bold-text {
  font-weight: bold !important;
}

.cursor-pointer {
  cursor: pointer !important;
  pointer-events: auto;
}

.italic-text {
  font-style: italic;
}

.cursor-default {
  cursor: default;
}

.content {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;

  &.height100 {
    height: 100%;
  }
}

.page-content-wrapper {
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 24px;

  @include respond-to(xs) {
    padding: 16px;
  }

  &.home-AD {
    max-width: 1100px;
    margin: 0 auto;
    padding: 24px;
  }
}

.contributor-content {
  width: 100%;
  max-width: 980px;
  margin: 32px auto;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.top-tooltip {
  &::after {
    content: "";
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 10px 10px;
    border-color: transparent transparent #17238c transparent;
  }
}

// Stile per comportamento team-sidebar e layout structure
.wrapper-layout-structure {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  min-height: calc(100% - 68px);

  .team-sidebar {
    display: none;
    align-self: stretch;
    background-color: #f9fafc;

    &.open-on-mobile {
      @include respond-to(xs) {
        display: flex;
        width: 100%;
      }

      @include respond-to(sm) {
        display: flex;
        width: 100%;
      }
    }

    @media only screen and (min-width: 960px) and (max-width: 1099px) {
      display: flex;
      width: 100%;
      max-width: 79px;
    }

    @media only screen and (min-width: 1100px) {
      display: flex;
      width: 100%;
      max-width: 250px;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        max-width: 350px;
      }
    }
  }

  .router-outlet-wrapper {
    align-self: stretch;
    width: 100%;

    @include respond-to(xs) {
      padding: 0;
    }
  }
}

// contenitore pagina
.main-content-wrapper {
  width: 100%;
  background-color: $soft-gray;
  padding: 60px 40px 40px 40px;
  border-radius: 0px 0px 10px 10px;

  &.no-sti-padding {
    padding-bottom: 40px !important;
    padding: 0px;
  }

  &.goal-details {
    padding: 32px 40px;
    background: linear-gradient(180deg, #ecf0f8 0%, #f9fafc 100%);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include layout-column-gap(16px);

    @include respond-to(xs) {
      padding: 32px 16px;
    }
  }

  @include respond-to(xs) {
    padding: 60px 16px 32px 16px;
  }
}

// Classi generiche per i componenti
.box-collection-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 24px;
  background: linear-gradient(180deg, #ecf0f8 0%, rgba(249, 250, 252, 0) 100%);
  border-radius: 10px;
  margin-top: 24px;

  @include respond-to(xs) {
    border-radius: inherit;
    margin-top: 0;
  }

  .row-boxes {
    width: 100%;
  }
}

// Spinner loader
.loader {
  margin: 50px auto !important;
  background-repeat: no-repeat;
  position: relative;
  -webkit-animation: load3 1s infinite linear;
  animation: load3 1s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);

  &.spinner-big {
    width: 110px;
    min-width: 110px;
    max-width: 110px;
    height: 110px;
    min-height: 110px;
    max-height: 110px;
    background-image: url(/assets/img/loader/loader-spinner-big.svg);
  }

  &.spinner-medium {
    width: 70px;
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    min-height: 70px;
    max-height: 70px;
    background-image: url(/assets/img/loader/loader-spinner-medium.svg);
  }

  &.spinner-small {
    width: 24px;
    min-width: 24px;
    max-width: 24px;
    height: 24px;
    min-height: 24px;
    max-height: 24px;
    background-image: url(/assets/img/loader/loader-spinner-small.svg);
  }
}

@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.accordion-content {
  width: 100%;
  padding: 16px 24px;

  &.text {
    width: 100%;

    p {
      word-break: break-word;
      width: 100%;
      color: $black;
    }
  }
}

// Contenitore accordion sfide
.challenge-accordion-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 24px;
  padding-top: 24px;
  @include layout-column-gap(24px);

  @include respond-to(xs) {
    padding: 0 16px;
    padding-top: 16px;
    @include layout-column-gap(16px);
  }
}

// Componente mini-card
.mini-card {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px 6px;
  box-sizing: border-box;
  border-radius: 3px;
  flex-grow: 0;

  margin-top: 2px;
  margin-bottom: 2px;

  &.default {
    border: 1px solid $gray1;

    p {
      color: $gray1;
    }
  }

  &.blue {
    border: 1px solid $primary;

    p {
      color: $primary;
    }
  }

  &.accent {
    border: 1px solid $accent;

    p {
      color: $accent;
    }
  }

  p {
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 20px;
    display: flex;
    align-items: center;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }
}

//guidelines
.guidelines {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &.height100 {
    height: 100%;
  }

  svg-icon {
    width: 100%;
    @include fixed-height(100px);
    text-align: center;
  }

  .text-wrapper {
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    @include layout-column-gap(10px);

    .title {
      width: 100%;
      font-style: normal;
      font-weight: 600;
      font-size: 30px;
      line-height: 38px;
      text-align: center;
      letter-spacing: 0.2px;
      color: $primary;
      margin: 24px 0;
    }

    p {
      width: 100%;
      text-align: left;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 24px;
      color: $gray1;
    }
  }
}

// Component centralization

.close-container {
  cursor: pointer;
  height: 32px;
  width: 100px;
  background-color: $black;
  border-radius: 0px 0px 4px 4px;

  svg-icon svg {
    color: $white;
  }

  &.primary {
    background-color: $error;
  }

  &.disabled {
    background-color: $placeholder;
  }
}

ng-dropdown-panel {
  z-index: 100000000 !important;
}

// #region Standard tags per il progetto

h1,
h2,
h3,
h4,
h5,
h6,
p,
span {
  &.white {
    color: white;
  }

  &.white-important {
    color: white !important;
  }

  &.black {
    color: $black;
  }

  &.primary {
    color: $primary;
  }

  &.grey {
    color: $placeholder;
  }

  &.light-grey {
    color: $gray1;
  }

  &.red {
    color: $error-light;
  }

  &.center {
    text-align: center;
  }

  &.right {
    text-align: right;
  }

  &.normal {
    font-weight: normal !important;
  }

  &.lighter {
    font-weight: lighter !important;
  }

  &.bold {
    font-weight: bold !important;
  }

  &.sub-title {
    font-weight: 500;
  }

  &.light {
    font-weight: 400;
  }

  &.underline {
    text-decoration: underline;
  }

  &.uppercase {
    text-transform: uppercase;
  }

  &.nowrap {
    white-space: nowrap;
  }

  &.clickable {
    cursor: pointer;
    pointer-events: auto;
  }

  &.disabled {
    color: $placeholder;
    cursor: default;
    pointer-events: none;
  }
}

h1 {
  font-size: 38px;
  font-style: normal;
  font-weight: bold;
  line-height: 46px;
  color: $primary;
  text-align: left;
  text-align: start;
  margin: 0;

  @include respond-to(xs) {
    font-size: 32px;
    line-height: 38px;
  }

  &.big {
    font-size: 48px;
    line-height: 48px;
  }
}

h2 {
  font-size: 36px;
  line-height: 20px;
  font-style: normal;
  font-weight: normal;
  color: $primary;
  text-align: left;
  text-align: start;
  margin: 0;

  @include respond-to(xs) {
    font-size: 28px;
  }

  &.small {
    font-size: 32px;
    line-height: 56px;

    @include respond-to(xs) {
      font-size: 26px;
    }
  }
}

h3 {
  font-size: 24px;
  line-height: 28px;
  font-style: normal;
  font-weight: normal;
  color: $black;
  text-align: left;
  text-align: start;
  margin: 0;

  @include respond-to(xs) {
    font-size: 22px;
  }

  &.small {
    font-size: 20px;
  }
}

h4 {
  font-size: 18px;
  line-height: 27px;
  font-weight: normal;
  font-style: normal;
  color: $black;
  text-align: left;
  text-align: start;
  margin: 0;
}

h5 {
  font-size: 16px;
  line-height: 24px;
  font-style: normal;
  font-weight: normal;
  color: $black;
  text-align: left;
  text-align: start;
  margin: 0;
}

h6 {
  font-size: 14px;
  line-height: 14px;
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.01em;
  color: $primary;
  text-align: left;
  text-align: start;
  margin: 0;

  &.small {
    font-size: 12px;
    line-height: 12px;
  }

  &.extra-small {
    font-size: 10px;
    line-height: 10px;
    color: $black;
  }

  &.description {
    line-height: 20px;
  }
}

// #endregion

// To fix date on sidenav
.cdk-overlay-container {
  z-index: 1001 !important;
}

.margin-right5 {
  margin-right: 5px;
}

.faq-support-container {
  cursor: pointer;
  background: #ffffff;
  border: 1px solid #f4f0ef;
  box-sizing: border-box;
  box-shadow:
    0px 24px 32px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 4px 8px rgba(0, 0, 0, 0.04),
    0px 0px 1px rgba(0, 0, 0, 0.04);
  border-radius: 20px;
  width: 86px;
  height: 87px;
  min-width: 86px;
  min-height: 87px;
  max-width: 86px;
  max-height: 87px;

  p {
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 12px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #c22d2d;
  }

  &:hover {
    background-color: #c22d2d;

    p {
      color: #ffffff !important;
    }

    svg-icon {
      svg {
        path {
          fill: #ffffff !important;
        }
      }
    }
  }

  &.responsive {
    border-radius: 10px;
    width: 52px;
    height: 52px;
    min-width: 52px;
    min-height: 52px;
    max-width: 52px;
    max-height: 52px;
    border-radius: 16px;
  }
}

.truncate-with-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.to-uppercase {
  text-transform: uppercase;
}

.modal-upload-divider {
  width: 100%;
  height: 1px;
  background: $gray5;
}

.margin-right16 {
  margin-right: 16px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.underlined {
  text-decoration: underline;
}

// Per le modali non predefinite
.modal-title-wrapper {
  width: 100%;
  margin-bottom: 24px;
}

.modal-text-wrapper {
  width: 100%;
  // @include layout-column-gap(8px);
  padding: 24px 0;
  border-top: 1px solid $gray5;
  text-align: center;
  line-height: 24px;

  &.body-scrolled {
    max-height: 300px;
    overflow-x: auto;
  }

  .link {
    cursor: pointer;
    font-weight: 700;
  }
}

.default-modal-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;

  .margin-top16 {
    margin-top: 16px;
  }

  h2 {
    width: 100%;

    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 56px;
    text-align: center;
    color: #ba3c33;
    padding-bottom: 24px;
    border-bottom: 1px solid #e6e6e6;
  }

  .modal-divisor {
    height: 1px !important;
    width: 100%;
    background-color: #e6e6e6;
  }

  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #727272;
  }

  .def-modal-info {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #333333;
    width: 100%;
  }

  // colore chgeckbox #17238C
}

.details-list {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  margin-top: 8px;
}

.junior-form {
  width: 100%;
  min-height: 80px;
  background: $light-grey;
  border-radius: 4px;
  padding: 16px 24px;

  span {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: $black;
  }
}

.button-dropdown {
  z-index: 100000000000000 !important;
}

.margin-top10 {
  margin-top: 10px;
}

.no-margin {
  margin: 0 !important;
}

// Riquardo volante con faq e supporto
.faq-support-container-fixed {
  position: fixed;
  right: 9%;
  bottom: 7%;

  @include respond-to(xs) {
    right: 4%;
  }
}
